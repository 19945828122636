import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { compareDocentes } from "../utils/docentes";
import { normalizeString } from "../utils/strings";
import { useWindowContext } from "../hooks/useWindowContext";
import { useToast } from "../context/ToastContext";

const Docentes = () => {
  const { user } = useAuthContext();
  const [docentes, setDocentes] = useState(null);
  const [docentesLoading, setDocentesLoading] = useState(true);
  const [mostrarNoActivos, setMostrarNoActivos] = useState(false);
  const [buscar, setBuscar] = useState("");
  const navigate = useNavigate();
  const [hover, setHover] = useState(null);
  const location = useLocation();
  const { showToast } = useToast();
  const { small } = useWindowContext();

  useEffect(() => {
    if (location.state?.alert) {
      const { message, type } = location.state.alert;
      showToast(message, type);
      // Limpiar el estado para que no se muestre nuevamente
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleBajar = (path, filename) => async () => {
    try {
      const response = await axios.get(path, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Nombre del archivo a bajar
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Limpia la URL creada
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  useEffect(() => {
    const fetchDocentes = async () => {
      setDocentesLoading(true);
      try {
        const response = await axios.get(`/api/admin/docentes`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const d = response.data
          .map((d) => ({
            ...d,
            legajo: d.legajo ? d.legajo : "",
            email: d.email ? d.email : "",
            celular: d.celular ? d.celular : "",
          }))
          .sort(compareDocentes);
        setDocentes(d);
      } catch (error) {
        showToast("Error al cargar los docentes." + error.message, "error");
      } finally {
        setDocentesLoading(false);
      }
    };
    fetchDocentes();
  }, [user.token]);

  return !docentes ? null : (
    <div className="ver-docentes">
      <h2>Docentes</h2>
      <div className="top-bar">
        <div className="nuevo-docente-mostrar-inactivos">
          <button
            className="btn"
            onClick={() => {
              navigate("/admin/docentes/nuevo");
            }}
          >
            Nuevo docente
          </button>
          <button
            className="btn"
            onClick={handleBajar(`/api/admin/docentes/csv`, "Docentes.csv")}
          >
            Bajar csv
          </button>
          <span className="mostrar-inactivos">
            <input
              type="checkbox"
              id="mostrar-no-activos"
              onChange={(e) => {
                setMostrarNoActivos(e.target.checked);
              }}
              value={mostrarNoActivos}
            />
            <label htmlFor="mostrar-no-activos">
              {small ? "Mostrar inactivos" : "Mostrar docentes inactivos"}
            </label>
          </span>
        </div>
        <div className="buscar">
          Buscar:{" "}
          <input
            type="text"
            onChange={(e) => {
              setBuscar(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <table className="tabla-docentes">
        <thead>
          <tr>
            <th>Apellido</th>
            <th>Nombre</th>
            {!small && <th>Legajo</th>}
            <th>DNI</th>
            {!small && (
              <>
                <th>Cargo</th>
                <th>Ded.</th>
                <th>#</th>
                <th>Email</th>
                <th>Celular</th>
              </>
            )}
            {mostrarNoActivos && <th>Activo</th>}
          </tr>
        </thead>
        <tbody>
          {docentes
            .filter((d) => mostrarNoActivos || d.activo === "si")
            .filter((d) =>
              Object.values(d)
                .map((v) => normalizeString(v.toString()))
                .some((s) => s.includes(normalizeString(buscar)))
            )
            .sort(compareDocentes)
            .map((d) => (
              <tr
                key={`tr-${d.dni}`}
                className={hover === d.dni ? "hover" : ""}
                onMouseEnter={() => {
                  setHover(d.dni);
                }}
                onMouseLeave={() => {
                  setHover(null);
                }}
                onClick={() => {
                  navigate(`/admin/docentes/${d.dni}`);
                }}
              >
                <td className="mw150">{d.apellido}</td>
                <td className="mw150">{d.nombre}</td>
                {!small && <td>{d.legajo}</td>}
                <td>{d.dni}</td>
                {!small && (
                  <>
                    <td>{d.cargo}</td>
                    <td>{d.dedicacion}</td>
                    <td>{d.cantidad}</td>
                    <td>{d.email}</td>
                    <td>{d.celular}</td>
                  </>
                )}
                {mostrarNoActivos && (
                  <td className={d.activo === "si" ? "activo" : "no-activo"}>
                    {d.activo === "si" ? "Sí" : "No"}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default Docentes;
