import React from "react";
import { Outlet } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { getCuat } from "../utils/strings";
import { useWindowContext } from "../hooks/useWindowContext";

// Componente de Ruta Protegida para administradores
const EncuestaRoutes = () => {
  const { anio, cuat } = useConfig();
  const { small } = useWindowContext();
  return (
    <>
      <div className="encuesta-routes">
        <h2>{`${small ? "Horarios" : "Encuesta de horarios"} - ${getCuat(
          cuat
        )} ${small ? "cuat." : "cuatrimestre de"} ${anio}`}</h2>
      </div>
      <Outlet />
    </>
  );
};

export default EncuestaRoutes;
