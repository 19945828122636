import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { compareDocentes } from "../utils/docentes";

const LARJ = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [licencias, setLicencias] = useState([]);
  const [aumentarDed, setAumentarDed] = useState([]);
  const [renuncias, setRenuncias] = useState([]);
  const [jubilaciones, setJubilaciones] = useState([]);
  const [LARJLoading, setLARJLoading] = useState(true);

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchLARJ = async () => {
      setLARJLoading(true);
      try {
        const response = await axios.get(
          `/api/admin/encuesta/larj/${anio}/${cuat}`,
          reqHeader
        );
        const docentes = response.data.sort(compareDocentes);
        setLicencias(docentes.filter((d) => d.licencia === "si"));
        setAumentarDed(docentes.filter((d) => d.aumentarDed === "si"));
        setRenuncias(docentes.filter((d) => d.renuncia === "si"));
        setJubilaciones(docentes.filter((d) => d.jubilacion === "si"));
      } catch (error) {
        // showAlert(
        //   <span>{`Error al cargar los envíos: ${error.message}`}</span>,
        //   "error"
        // );
      } finally {
        setLARJLoading(false);
      }
    };
    fetchLARJ();
  }, []);

  return LARJLoading ? null : (
    <div className="estadisticas-container">
      {licencias.length > 0 && (
        <div>
          <h3>Licencia</h3>
          <ul>
            {licencias.map((d) => (
              <li key={`envio-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {aumentarDed.length > 0 && (
        <div>
          <h3>Aumentar dedicación</h3>
          <ul>
            {aumentarDed.map((d) => (
              <li key={`envio-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {renuncias.length > 0 && (
        <div>
          <h3>Renuncia</h3>
          <ul>
            {renuncias.map((d) => (
              <li key={`envio-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {jubilaciones.length > 0 && (
        <div>
          <h3>Jubilación</h3>
          <ul>
            {jubilaciones.map((d) => (
              <li key={`envio-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LARJ;
