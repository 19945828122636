import { Navigate, Outlet, NavLink } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavContext } from "../context/NavContext";
import { useWindowContext } from "../hooks/useWindowContext";

// Componente de Ruta Protegida para administradores
const LeftPanel = () => {
  const { user } = useAuthContext();
  const { isOpen, closeNav } = useNavContext();
  const { small } = useWindowContext();

  if (!user) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="panel-container">
      {user?.rol === "ADMIN" && (isOpen || !small) && (
        <nav className="panel">
          <ul>
            <li className="single">
              <NavLink
                to="/encuesta"
                onClick={closeNav}
                className={({ isActive }) => (isActive ? "activo" : "")}
              >
                Encuesta
              </NavLink>
            </li>
            <li className="single">
              <NavLink
                onClick={closeNav}
                to="/cambiar-password"
                className={({ isActive }) => (isActive ? "activo" : "")}
              >
                Cambiar contraseña
              </NavLink>
            </li>

            <li className="single">
              <NavLink to="/admin/docentes" onClick={closeNav}>
                Docentes
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/encuesta" onClick={closeNav}>
                Administrar encuesta
              </NavLink>
              <ul>
                <li>
                  <NavLink to="/admin/encuesta/aulas" onClick={closeNav}>
                    Aulas
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/carga" onClick={closeNav}>
                    Cargas horarias
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/ver-envios" onClick={closeNav}>
                    Estadísticas
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/encuesta/tt" onClick={closeNav}>
                    TachaTacha
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/encuesta/horarios-asignados"
                    onClick={closeNav}
                  >
                    Asignar horarios
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      )}
      <div className={user.rol === "ADMIN" ? "content" : "container"}>
        <Outlet />
      </div>
    </div>
  );
};

export default LeftPanel;
