import { useNavContext } from "../context/NavContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import { useWindowContext } from "../hooks/useWindowContext";

const Navbar = () => {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const { small } = useWindowContext();
  const { isOpen, closeNav, openNav } = useNavContext();
  return (
    <header>
      <div className="navbar-container">
        {user?.rol === "ADMIN" && small && (
          <div className="hamb">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              viewBox="0 0 4.756 4.063"
              onClick={isOpen ? closeNav : openNav}
            >
              <path d="M0 0h4.756v.9H0zM0 1.581h4.756v.9H0zM0 3.163h4.756v.9H0z" />
            </svg>
          </div>
        )}
        {(!small || user?.rol !== "ADMIN") && <h1>CBC Matemática</h1>}
        {user && (
          <div className="logout">
            {!small && <span>{`(${user.nombre}) `}</span>}
            <button onClick={logout} className="btn cerrar-sesion">
              Cerrar sesión
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
