import { createContext, useState, useContext } from "react";

export const NavContext = createContext();

export const useNavContext = () => {
  const context = useContext(NavContext);

  if (!context) {
    throw Error("useNavContext debe usarse dentro de NavContextProvider");
  }

  return context;
};

export const NavContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <NavContext.Provider
      value={{
        isOpen,
        openNav: () => {
          setIsOpen(true);
        },
        closeNav: () => {
          setIsOpen(false);
        },
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
