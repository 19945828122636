import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useConfig } from "../hooks/useConfig";

const ArchivosTachaTacha = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const handleBajar = (path, filename) => async () => {
    try {
      const response = await axios.get(path, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Nombre del archivo a bajar
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Limpia la URL creada
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };
  return (
    <div className="archivos-tt">
      <h3>Preparar el TachaTacha</h3>
      <ul>
        <li>
          Copiar la carpeta TachaTacha del último cuatrimestre. Eliminar todos
          los archivos que hay en la copia, excepto:
          <ul>
            <li>TachaTacha.exe</li>
            <li>Horarios.txt</li>
          </ul>
        </li>
        <li>
          Bajar los siguientes archivos y guardarlos en dicha carpeta:
          <div className="bajar-tt">
            <button
              className="btn secondary"
              onClick={handleBajar(
                `/api/admin/tt/aulas/${anio}/${cuat}`,
                "Aulas.txt"
              )}
            >
              Bajar Aulas.txt
            </button>

            <button
              className="btn secondary"
              onClick={handleBajar(
                `/api/admin/tt/docentes/${anio}/${cuat}`,
                "Docentes.txt"
              )}
            >
              Bajar Docentes.txt
            </button>
            <button
              className="btn secondary"
              onClick={handleBajar(
                `/api/admin/tt/elecciones/${anio}/${cuat}`,
                "Elecciones.txt"
              )}
            >
              Bajar Elecciones.txt
            </button>
            <button
              className="btn secondary"
              onClick={handleBajar(
                `/api/admin/tt/sedes/${anio}/${cuat}`,
                "Sedes.txt"
              )}
            >
              Bajar Sedes.txt
            </button>
          </div>
          El TachaTacha del nuevo cuatrimestre está listo para usar.
        </li>
      </ul>
    </div>
  );
};

export default ArchivosTachaTacha;
