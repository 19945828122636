import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { compareDocentes } from "../utils/docentes";
import { useConfig } from "../hooks/useConfig";

const Comentarios = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [comentariosLoading, setComentariosLoading] = useState(true);
  const [comentarios, setComentarios] = useState([]);
  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchComentarios = async () => {
      setComentariosLoading(true);
      try {
        const response = await axios.get(
          `/api/admin/encuesta/comentarios/${anio}/${cuat}`,
          reqHeader
        );
        setComentarios(response.data.sort(compareDocentes));
      } catch (error) {
        console.error("Error al cargar los comentarios." + error.message);
      } finally {
        setComentariosLoading(false);
      }
    };
    fetchComentarios();
  }, []);

  return (
    <div className="comentarios-container">
      <h3>Comentarios</h3>
      <div className="lista-comentarios">
        {comentarios.length > 0 &&
          comentarios.map((c) => (
            <div key={`comentario-${c.apellido}-${c.nombre}`}>
              <h4>{`${c.apellido}, ${c.nombre}`}</h4>
              <p>{c.comentarios}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Comentarios;
