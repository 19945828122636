import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { message } from "../utils/errors";
import { useToast } from "../context/ToastContext";
import { handleKeyDownNum, handlePasteNum } from "../utils/forms";

const VerDocente = () => {
  const { dni } = useParams();
  const { user } = useAuthContext();
  const [docente, setDocente] = useState(null);
  const [cambios, setCambios] = useState(null);
  const [docenteLoading, setDocenteLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const { showToast } = useToast();

  const handleConfirm = (e) => {
    e.preventDefault();
    if (inputValue === "blanquear") {
      setShowModal(false);
      setInputValue("");
      handleBlanquear();
    } else {
      window.alert(`Por favor, escriba "blanquear" para confirmar.`);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      inputRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const fetchDocente = async () => {
      setDocenteLoading(true);
      try {
        const response = await axios.get(`/api/admin/docentes/${dni}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const d = {
          ...response.data,
          dni: response.data.dni.toString(),
          cantidad: response.data.cantidad.toString(),
          legajo: response.data.legajo ? response.data.legajo.toString() : "",
        };
        setDocente(d);
        setCambios({
          apellido: d.apellido,
          nombre: d.nombre,
          legajo: d.legajo,
          cargo: d.cargo,
          dedicacion: d.dedicacion,
          cantidad: d.cantidad,
          activo: d.activo,
        });
      } catch (error) {
        setError(message(error));
      } finally {
        setDocenteLoading(false);
      }
    };
    fetchDocente();
  }, [dni, user]);

  const buscarCambios = (cambios) => {
    if (
      docente.apellido !== cambios.apellido ||
      docente.nombre !== cambios.nombre ||
      docente.legajo !== cambios.legajo ||
      docente.cargo !== cambios.cargo ||
      docente.dedicacion !== cambios.dedicacion ||
      docente.cantidad !== cambios.cantidad ||
      docente.activo !== cambios.activo
    ) {
      showToast("Hay cambios sin guardar", "warning");
    }
  };

  const handleGuardar = async (e) => {
    e.preventDefault();
    if (
      cambios.legajo !== "" &&
      (cambios.legajo.length > 6 || cambios.legajo.length < 5)
    ) {
      showToast("Legajo inválido", "error");
      return;
    }
    let d = {
      apellido: cambios.apellido,
      nombre: cambios.nombre,
      cargo: cambios.cargo,
      dedicacion: cambios.dedicacion,
      cantidad: parseInt(cambios.cantidad),
      activo: cambios.activo,
    };
    if (cambios.legajo !== "") {
      d.legajo = parseInt(cambios.legajo);
    }
    try {
      const response = await axios.put(`/api/admin/docentes/${dni}`, d, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setDocente({
        ...docente,
        ...cambios,
      });
      showToast("Cambios guardados exitosamente", "success");
    } catch (error) {
      showToast("Error al guardar los cambios." + error.message, "error");
    } finally {
    }
  };

  const handleBlanquear = async () => {
    try {
      const response = await axios.put(
        `/api/admin/docentes/blanquear`,
        { dni: docente.dni },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      showToast("Contraseña blanqueada exitosamente", "success");
    } catch (error) {
      showToast("Error al blanquear la contraseña." + error.message, "error");
    } finally {
    }
  };

  if (docenteLoading) return null;
  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="ver-docente">
        <h2>Editar docente</h2>
        <form onSubmit={handleGuardar}>
          <div className="parent">
            <div className="estado">
              <label htmlFor="estado">Estado</label>
              <select
                value={cambios.activo}
                className={`${
                  cambios.activo === "si" ? "activo" : "no-activo"
                } ${cambios.activo !== docente.activo && "modified"}`}
                onChange={(e) => {
                  const newCambios = { ...cambios, activo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="no" className="no-activo">
                  Inactivo
                </option>
                <option value="si" className="activo">
                  Activo
                </option>
              </select>
            </div>
            <div className="nombre">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                className={docente.nombre === cambios.nombre ? "" : "modified"}
                value={cambios.nombre}
                onChange={(e) => {
                  const newCambios = { ...cambios, nombre: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="apellido">
              <label htmlFor="apellido">Apellido</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={cambios.apellido}
                className={
                  docente.apellido === cambios.apellido ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, apellido: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="dni">
              <label htmlFor="dni">DNI</label>
              <input
                type="text"
                id="dni"
                name="dni"
                value={docente.dni}
                disabled
              />
            </div>
            <div className="legajo">
              <label htmlFor="legajo">Legajo</label>
              <input
                type="text"
                id="legajo"
                name="legajo"
                className={docente.legajo === cambios.legajo ? "" : "modified"}
                value={cambios.legajo}
                onPaste={handlePasteNum}
                onKeyDown={handleKeyDownNum}
                onChange={(e) => {
                  const newCambios = { ...cambios, legajo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              />
            </div>
            <div className="cargo">
              <label htmlFor="cargo">Cargo</label>
              <select
                id="cargo"
                value={cambios.cargo}
                className={docente.cargo === cambios.cargo ? "" : "modified"}
                onChange={(e) => {
                  const newCambios = { ...cambios, cargo: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="AY2">AY2</option>
                <option value="AY1">AY1</option>
                <option value="ADJ">ADJ</option>
                <option value="ASO">ASO</option>
                <option value="TIT">TIT</option>
              </select>
            </div>
            <div className="dedicacion">
              <label htmlFor="dedicacion">Dedicación</label>
              <select
                id="dedicacion"
                value={cambios.dedicacion}
                className={
                  docente.dedicacion === cambios.dedicacion ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, dedicacion: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="SIM">SIM</option>
                <option value="S/E">S/E</option>
                <option value="S+1">S+1</option>
                <option value="EXC">EXC</option>
              </select>
            </div>
            <div className="cantidad">
              <label htmlFor="cantidad">#</label>
              <select
                id="cantidad"
                value={cambios.cantidad}
                className={
                  docente.cantidad === cambios.cantidad ? "" : "modified"
                }
                onChange={(e) => {
                  const newCambios = { ...cambios, cantidad: e.target.value };
                  setCambios(newCambios);
                  buscarCambios(newCambios);
                }}
                disabled={showModal}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="nacimiento">
              <label htmlFor="nacimiento">Fecha de nacimiento</label>
              <input
                id="nacimiento"
                name="nacimiento"
                type="date"
                value={
                  docente.nacimiento ? docente.nacimiento.split("T")[0] : ""
                }
                disabled
              />
            </div>
            <div className="celular">
              <label htmlFor="celular">Celular</label>
              <input
                id="celular"
                name="celular"
                type="number"
                value={docente.celular ? docente.celular : ""}
                disabled
              />
            </div>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                value={docente.email ? docente.email : ""}
                disabled
              />
            </div>

            <div className="direccion">
              <label htmlFor="direccion">Dirección</label>
              <input
                id="direccion"
                name="direccion"
                type="text"
                value={docente.direccion ? docente.direccion : ""}
                disabled
              />
            </div>
            <div className="localidad">
              <label htmlFor="localidad">Localidad</label>
              <input
                id="localidad"
                name="localidad"
                type="text"
                value={docente.localidad ? docente.localidad : ""}
                disabled
              />
            </div>
            <div className="cp">
              <label htmlFor="cp">CP</label>
              <input
                id="cp"
                name="cp"
                type="text"
                value={docente.cp ? docente.cp : ""}
                disabled
              />
            </div>

            <div className="carrera">
              <label htmlFor="carrera">Carrera</label>
              <input
                id="carrera"
                name="carrera"
                type="text"
                value={docente.carrera ? docente.carrera : ""}
                disabled
              />
            </div>
            <div className="institucion">
              <label htmlFor="institucion">Institución</label>
              <input
                id="institucion"
                name="institucion"
                type="text"
                value={docente.institucion ? docente.institucion : ""}
                disabled
              />
            </div>
            <div className="avance">
              <label htmlFor="avance">Avance (%)</label>
              <input
                id="avance"
                name="avance"
                type="number"
                value={docente.avance ? docente.avance : ""}
                disabled
              />
            </div>
            <div className="foto">
              {docente.foto ? (
                <div>
                  <img
                    src={docente.foto}
                    alt="Vista previa"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                <svg
                  className="placeholder"
                  width="200px"
                  viewBox="0 0 133 157"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 124.421s4.46 5.131 7.07 7.663c3.082 2.99 6.927 6.27 9.202 7.85 5.18 3.597 8.625 5.63 13.504 7.972 9.532 4.575 18.711 7.022 30.285 8.075 8.174.743 20.893-.393 28.178-2.518 7.267-2.12 10.057-3.154 15.26-5.656 9.071-4.362 16.074-9.295 23.206-16.348 2.237-2.267 6.23-6.897 6.23-6.897s-.381-4.434-1.378-10.428c-.859-5.348-1.522-6.992-3.824-9.48-1.156-1.247-2.003-1.849-3.883-2.756-4.1-1.979-10.411-4.157-21.985-7.59-4.075-1.209-9.191-2.946-12.789-4.343l-3.264-1.268-.92-1.825a45.82 45.82 0 0 1-1.693-3.927c-.743-2.015-1.84-6.714-1.84-7.884 0-.348.984-1.577 2.615-3.266 4.19-4.336 4.851-5.291 6.631-9.585a579.4 579.4 0 0 1 1.548-3.704c.248-.582 1.133-2.1 1.966-3.37 3.009-4.595 3.625-6.615 3.832-12.553.123-3.512.051-4.576-.57-8.467-.39-2.448-.874-5.283-1.076-6.302a43.43 43.43 0 0 1-.536-3.704c-.367-4.013-2.401-8.195-6.141-12.627C85.286 6.338 79.205 2.45 72.89.781c-7.106-1.877-15.2-.333-22.097 4.215C44.568 9.1 39.04 16.129 37.714 21.624c-.154.64-.39 2.115-.523 3.28-.133 1.164-.676 4.616-1.205 7.672-.906 5.224-.956 5.81-.84 9.79.18 6.154.763 8.1 3.82 12.77.834 1.27 1.72 2.788 1.969 3.37.25.582.998 2.355 1.665 3.94 1.77 4.21 3.085 5.998 8.134 11.052.546.547.992 1.248.992 1.558 0 1.174-1.095 5.87-1.84 7.889a45.82 45.82 0 0 1-1.693 3.927l-.92 1.825-3.263 1.268c-4.288 1.665-8.596 3.088-17.287 5.708-8.21 2.475-13.425 4.32-17.198 6.085-2.194 1.026-2.903 1.516-4.154 2.872-2.294 2.487-2.964 4.149-3.836 9.515-.407 2.504-.8 4.55-.873 4.546A120.72 120.72 0 0 1 0 124.42Z" />
                </svg>
              )}
            </div>
          </div>
          <div className="gap-1">
            <button onClick={handleGuardar} className="btn">
              Guardar
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/admin/docentes");
              }}
              className="btn secondary"
            >
              Volver
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="btn"
            >
              Blanquear contraseña
            </button>
          </div>
        </form>
      </div>
      {showModal && (
        <div className="modal">
          <div className="title">
            <h3>Confirmar blanqueo de constraseña</h3>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
          </div>
          <p>{`Confirme que desea blanquear la contraseña de ${docente.apellido}, ${docente.nombre}.`}</p>
          <form onSubmit={handleConfirm}>
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Escriba "blanquear"`}
              autoCapitalize="off"
            />
            <button
              type="submit"
              className="btn"
              disabled={inputValue !== "blanquear"}
            >
              Confirmar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default VerDocente;
