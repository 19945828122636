import { useState, useEffect } from "react";
import { useConfig } from "../hooks/useConfig";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import sedes from "../constants/sedes";
import horarios from "../constants/horarios";
import { materiasOfertaHoraria } from "../constants/materias";
import { useWindowContext } from "../hooks/useWindowContext";
import { useNavigate, useParams } from "react-router-dom";

const materias = materiasOfertaHoraria;

const VerHorariosAsignados = () => {
  const { dni } = useParams();
  const { anio, cuat } = useConfig();
  const { user } = useAuthContext();
  const [horariosAsignados, setHorariosAsignados] = useState(null);
  const [horariosAsignadosLoading, setHorariosAsignadosLoading] =
    useState(true);
  const [error, setError] = useState(null);
  const { small } = useWindowContext();
  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const navigate = useNavigate();

  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchHorariosAsignados = async () => {
      setHorariosAsignadosLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `/api/admin/horarios-asignados/${anio}/${cuat}/${dni}`,
          reqHeader
        );
        setHorariosAsignados(response.data.horarios);
        setNombre(response.data.nombre);
        setApellido(response.data.apellido);
      } catch (error) {
        setError("Error al cargar los horarios asignados:", error.message);
      } finally {
        setHorariosAsignadosLoading(false);
      }
    };
    fetchHorariosAsignados();
  }, [anio, cuat, setHorariosAsignados]);

  return (
    <>
      <div className="ver-horarios-asignados">
        {nombre && apellido && (
          <h3>{`Horarios asignados a ${apellido}, ${nombre}`}</h3>
        )}
        {error && (
          <p>
            Hubo un error al cargar los horarios asignados. Vuelva a intentar en
            otro momento.
          </p>
        )}
        {!horariosAsignadosLoading &&
          horariosAsignados &&
          !small &&
          horariosAsignados.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Sede</th>
                  <th>Materia</th>
                  <th>Horario</th>
                  <th>Horas</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                {horariosAsignados.map((h) => (
                  <tr key={`${h._id}`}>
                    <td>{sedes[h.sede]}</td>
                    <td>{materias[h.materia]}</td>
                    <td>{horarios[h.horario]}</td>
                    <td>{h.horas}</td>
                    <td>{h.comentario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        {!horariosAsignadosLoading &&
          horariosAsignados &&
          small &&
          horariosAsignados.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Materia</th>
                  <th>Horas</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                {horariosAsignados.map((h) => (
                  <tr key={`${h._id}`}>
                    <td className="materia">
                      <span>{sedes[h.sede]}</span>
                      <span>{materias[h.materia]}</span>
                      <span>{horarios[h.horario]}</span>
                    </td>
                    <td>{h.horas}</td>
                    <td>{h.comentario}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        <button
          className="btn"
          onClick={() => {
            navigate("/admin/encuesta/horarios-asignados");
          }}
        >
          Volver
        </button>
      </div>
    </>
  );
};
export default VerHorariosAsignados;
