import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { compareDocentes } from "../utils/docentes";

const VerEnvios = () => {
  const { user } = useAuthContext();
  const { anio, cuat } = useConfig();
  const [enviaronsLoading, setEnviaronsLoading] = useState(true);
  const [noEntraron, setNoEntraron] = useState([]);
  const [entraron, setEntraron] = useState([]);
  const [guardaron, setGuardaron] = useState([]);
  const [enviaron, setEnviaron] = useState([]);
  const navigate = useNavigate();
  const reqHeader = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    const fetchEnvios = async () => {
      setEnviaronsLoading(true);
      try {
        const response = await axios.get(
          `/api/admin/encuesta/docentes`,
          reqHeader
        );
        const docentes = response.data.filter((d) => d.activo === "si");
        setEnviaron(docentes.filter((d) => d.envio).sort(compareDocentes));
        setGuardaron(
          docentes.filter((d) => !d.envio && d.guardo).sort(compareDocentes)
        );
        setEntraron(
          docentes
            .filter((d) => !d.envio && !d.guardo && d.entro)
            .sort(compareDocentes)
        );
        setNoEntraron(
          docentes
            .filter((d) => !d.envio && !d.guardo && !d.entro)
            .sort(compareDocentes)
        );
      } catch (error) {
        // showAlert(
        //   <span>{`Error al cargar los envíos: ${error.message}`}</span>,
        //   "error"
        // );
      } finally {
        setEnviaronsLoading(false);
      }
    };
    fetchEnvios();
  }, []);

  return enviaronsLoading ? null : (
    <div className="estadisticas-container">
      {enviaron.length === 0 &&
        guardaron.length === 0 &&
        entraron.length === 0 &&
        noEntraron.length === 0 && (
          <p>
            No hay docentes con carga horaria asignada para este cuatrimestre.
          </p>
        )}
      <button
        onClick={() => navigate("/admin/encuesta/comentarios")}
        className="btn secondary"
      >
        Comentarios
      </button>
      <button
        onClick={() => navigate("/admin/encuesta/larj")}
        className="btn secondary"
      >
        Licencias / renuncias / etc.
      </button>
      {enviaron.length > 0 && (
        <div>
          <h3>{`Enviaron (${enviaron.length})`}</h3>
          <ul>
            {enviaron.map((d) => (
              <li key={`envio-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {guardaron.length > 0 && (
        <>
          <h3>{`Guardaron (${guardaron.length})`}</h3>
          <ul>
            {guardaron.map((d) => (
              <li key={`guardo-${d.dni}`}>
                <Link
                  to={`/admin/encuesta/${anio}/${cuat}/${d.dni}`}
                >{`${d.apellido}, ${d.nombre}`}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
      {entraron.length > 0 && (
        <>
          <h3>{`Entraron (${entraron.length})`}</h3>
          <ul>
            {entraron.map((d) => (
              <li key={`entro-${d._id}`}>{`${d.apellido}, ${d.nombre}`}</li>
            ))}
          </ul>
        </>
      )}
      {noEntraron.length > 0 && (
        <>
          <h3>{`No entraron (${noEntraron.length})`}</h3>
          <ul>
            {noEntraron.map((d) => (
              <li key={`no-entro-${d._id}`}>{`${d.apellido}, ${d.nombre}`}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default VerEnvios;
